var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{staticClass:"form-field w-full"},[_c('template',{slot:"extra"},[_vm._t("extra")],2),_c('div',{staticClass:"pb-8 p-4"},[_c('select-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.field,
          'key': 'type',
          'prefix': 'form_',
          'options': _vm.fieldOptions,
          'clearable': false,
          disabled: _vm.disabled,
        }
      }}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.field.config !== null)?_c('ul',{staticClass:"form-field__expanded-list p-4 mt-8"},_vm._l((Object.keys(_vm.field.config)),function(type,index){return (!['name', 'model', 'type'].includes(type))?_c('li',{key:index,staticClass:"mt-4 flex flex-col items-start"},[_c('div',{staticClass:"w-full"},[(type === 'label')?_c('text-input',{attrs:{"setup":{
                'func': _vm.configInputSetup,
                'args': {
                  'model': _vm.field.config,
                  'key': ("" + type),
                  'prefix': 'form_',
                  'validation': 'required',
                  disabled: _vm.disabled,
                }
              }},on:{"blur":_vm.onBlur}}):(typeof _vm.field.config[type] === 'string' && type !== 'expected')?_c('text-input',{attrs:{"setup":{
                'func': _vm.configInputSetup,
                'args': {
                  'model': _vm.field.config,
                  'key': ("" + type),
                  'prefix': 'form_',
                  disabled: _vm.disabled,
                }
              }}}):_vm._e(),(typeof _vm.field.config[type] === 'number')?_c('number-input',{attrs:{"setup":{
                'func': _vm.configInputSetup,
                'args': {
                  'model': _vm.field.config,
                  'key': ("" + type),
                  'prefix': 'form_',
                  'disabled': _vm.disabled,
                  'min': _vm.field.type === 'files' ? 0 : Number.MIN_SAFE_INTEGER,
                  'max': _vm.field.type === 'files' ? 10 : Number.MAX_SAFE_INTEGER
                }
              }},on:{"change":function (newVal) { return _vm.field.config[type] = parseInt(newVal); }}}):_vm._e(),(typeof _vm.field.config[type] === 'boolean' && type !== 'expected')?_c('switch-input',{attrs:{"setup":{
                'func': _vm.configInputSetup,
                'args': {
                  'model': _vm.field.config,
                  'key': ("" + type),
                  'prefix': 'form_',
                  'disabled': _vm.disabled,
                }
              }}}):_vm._e(),(Array.isArray(_vm.field.config[type]) && _vm.field.type !== 'files')?_c('chips-input',{attrs:{"setup":{
                'func': _vm.configInputSetup,
                'args': {
                  'model': _vm.field.config,
                  'key': ("" + type),
                  'prefix': 'form_',
                  'disabled': _vm.disabled
                }
              }}}):_vm._e(),(Array.isArray(_vm.field.config[type]) && _vm.field.type === 'files')?_c('select-input',{attrs:{"setup":{
                'func': _vm.configInputSetup,
                'args': {
                  'model': _vm.field.config,
                  'key': ("" + type),
                  'prefix': 'form_',
                  'options': _vm.filesTypeOptions,
                  'multiple': true,
                  'disabled': _vm.disabled
                }
              }}}):_vm._e(),(type === 'expected')?_c('select-input',{attrs:{"setup":{
                'func': _vm.configInputSetup,
                'args': {
                  'model': _vm.field.config,
                  'key': ("" + type),
                  'prefix': 'form_',
                  'options': _vm.expectedFieldOptions,
                  'clearable': false,
                  disabled: _vm.disabled,
                }
              }}}):_vm._e()],1)]):_vm._e()}),0):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(_vm.field.config !== null)?_c('div',{staticClass:"p-4"},[_c('span',{staticClass:"mb-2 mt-4"},[_vm._v(_vm._s(_vm.$t("form_item_example"))+":")]),_c('Field',{attrs:{"field":_vm.field}})],1):_vm._e()])],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_vm._t("footer")],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }
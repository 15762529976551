











































































































































































import makeId from '@/assets/utils/makeId'
import Field from '@/components/questionnaire/Field.vue'
import Form from '@/components/questionnaire/Form.vue'
import FormFieldItem from '@/components/questionnaire/FormFieldItem.vue'
import Questionnaire from '@/includes/logic/Questionnaire'
import Api from '../../../includes/logic/Api'
import { InputSetups } from '@/mixins/input-setups'
import { errorNotification } from '@/includes/NotificationService'

import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import Editor from 'piramis-base-components/src/components/Editor/Editor.vue'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'

import { cloneDeep, isUndefined, merge, capitalize } from 'lodash'
import arrayMove from 'array-move'
import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    FormFieldItem,
    Field,
    Form,
    ConfigField,
    Editor,
    makeId,
  },
})
export default class QuestionnaireForm extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {

  form: null | any = null

  isNewForm = false

  isSortable = false

  activeTab = 0

  isLoading = false

  get isDisabledSaveButton() {
    if (!isUndefined(this.form) && this.form) {
      if (!isUndefined(this.form.name) && this.form.name.length === 0) return true

      if (isUndefined(this.form.fields) || this.form.fields.length === 0) {
        return true
      } else if (!isUndefined(this.form.fields)) {
        for (let i = 0; i < this.form.fields.length; i++) {
          if (this.form.fields[i].config === null) {
            return true
          } else {
            if (this.form.fields[i].config.name.length === 0 || !/^([0-9a-zA-Z_\s]+)$/gm.test(this.form.fields[i].config.name)) return true
          }
        }
      }

      return false
    } else {
      return true
    }
  }

  get actionType() {
    return this.$route.params.actionType
  }

  get isValidActionType() {
    return [ 'new', 'edit', 'watch' ].includes(this.actionType)
  }

  get disableSetup() {
    return this.actionType === 'watch'
  }

  onDeleteQuestionnaireClick(): void {
    this.$confirm({
      onOk: () => {
        this.deleteForm()
      },
      title: this.$t('form_delete_title').toString(),
      okText: this.$t('pi_accept').toString(),
      cancelText: this.$t('pi_reject').toString()
    })
  }

  sendTest() {
    this.isLoading = true

    Questionnaire.submitFormTest(cloneDeep(this.form))
      .catch(errorNotification)
      .finally(() => {
        this.isLoading = false
      })
  }

  // eslint-disable-next-line
  movePosition(arr: Array<any>, index: number, newIndex: number) {
    this.$set(this.form, 'fields', arrayMove(cloneDeep(arr), index, newIndex))
  }

  createField() {
    this.form.fields.push({
      guid: makeId('10'),
      type: '',
      config: null,
    })
  }

  deleteInput(index: number) {
    this.form.fields.splice(index, 1)
  }

  deleteForm() {
    this.isLoading = true

    this.$store.dispatch('deleteForm', this.form.guid)
      .then(() => {
        Api.deleteForm('tg', {
          guid: this.form.guid,
          chat_id: this.$store.state.chatState.chat.chat_id,
        })
          .then(() => {
            this.$router.push({
              name: 'questionnaire',
              params: { [EntityTypes.CHAT_ID]: this.$store.state.chatState.chat.chat_id },
            })
          })
          .catch(errorNotification)
          .finally(() => {
            this.isLoading = false
          })
      })
  }

  saveForm() {
    this.isLoading = true
    const formCopy = cloneDeep(this.form)

    formCopy.fields.forEach((item: any) => {
      item.type = capitalize(item.type)
      item = merge(item, item.config)
      delete item.guid
      delete item.config
      delete item.testModel
      delete item.model
    })

    if (this.isNewForm) {
      delete formCopy.guid
    }

    Api.setForm('tg', {
      form: formCopy,
    })
      .then(() => {
        this.$router.push({
          name: 'questionnaire',
          params: { [EntityTypes.CHAT_ID]: this.$store.state.chatState.chat.chat_id },
        })
      })
      .catch(errorNotification)
      .finally(() => {
        this.isLoading = false
      })
  }

  // eslint-disable-next-line
  getForm(guid: string) {
    return new Promise((resolve, reject) => {
      this.$store.dispatch('getForms')
        .then(() => {
          this.$store.state.chatState.questionnaire.forms.map((form: any) => {
            if (form.guid === guid) {
              const convertedForm = Questionnaire.convertFormFromServer(form)

              convertedForm.chat_id = this.$store.state.chatState.chat.chat_id
              resolve(Questionnaire.convertFormFromServer(form))
            }
          })
        })
        .catch(reject)
    })
  }

  generateNewForm() {
    return {
      guid: makeId(128),
      chat_id: this.$store.state.chatState.chat.chat_id,
      name: this.$route.query.initName ?? '',
      description: '',
      fields: [],
    }
  }

  created() {
    this.$baseTemplate.saveButton.hide()
  }

  destroyed() {
    this.$baseTemplate.saveButton.show()
  }

  mounted() {
    if (!this.isValidActionType) {
      this.$router.replace({ name: 'error_404' })
    } else {
      if (this.actionType === 'new') {
        this.form = this.generateNewForm()
        this.isNewForm = true
      } else {
        this.isLoading = true

        this.getForm(this.$route.query.formGuid.toString())
          .then(form => {
            this.form = form
          })
          .catch(errorNotification)
          .finally(() => {
            this.isLoading = false
          })
      }
    }
  }
}

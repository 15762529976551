
import capitalize from "@/assets/utils/capitalize";
import Api        from "./Api";
import { cloneDeep, merge }          from "lodash";
import makeId     from "@/assets/utils/makeId";

export default class Questionnaire {
  static fieldTypesStructures = {
    text    : {
      // type           : "text",
      name       : "",
      label      : "",
      placeholder: "",
      description: "",
      required   : false,
    },
    textarea: {
      // type    : "textarea",
      name       : "",
      label      : "",
      description: "",
      required   : false,
    },
    select  : {
      // type        : "select",
      name        : "",
      placeholder : "",
      label       : "",
      description : "",
      values      : [],
      multiple    : false,
      autocomplete: false,
      required    : false,
      model       : null,
    },
    number  : {
      // type     : "number",
      name       : "",
      label      : "",
      description: "",
      min        : 0,
      max        : 100,
      step       : 1,
      required   : false,
      model      : 0,
    },
    date    : {
      // type       : "date",
      name       : "",
      label      : "",
      description: "",
      placeholder: "",
      required   : false,
      model      : null,
    },
    time    : {
      // type       : "time",
      name       : "",
      label      : "",
      description: "",
      required   : false,
      model      : null,
    },
    checkbox: {
      // type    : "checkbox",
      name       : "",
      label      : "",
      description: "",
      expected   : null,
      model      : false,
    },
    files: {
      name       	: "",
      label      	: "",
      description: "",
      minCount		: 0,
      maxCount		: 1,
      acceptedTypes	: [],
      acceptAsDocuments	: false,
      attachToMessage	: false,
      model      	: [],
    }
  };

  static runForm(action, data) {
    return Api.runForm("tg", {
      action,
      data,
    });
  }

  static convertFormFromServer(form) {
    const convertedForm = cloneDeep(form);

    convertedForm.fields.forEach(field => {
      field.config = {
	model: null,
      };

      field.guid = makeId(10);
      field.type = field.type.toLowerCase();

      merge(field.config, Questionnaire.fieldTypesStructures[ field.type ]);

      Object.keys(field).map(key => {
	if(Object.keys(field.config).includes(key)) {
	  field.config[ key ] = field[ key ] !== null ? cloneDeep(field[ key ]) : null;
	  delete field[ key ];
	}
	return true;
      });

    });
    return convertedForm;
  }

  static submitForm(form, data) {
    const values = {};

    form.fields.map(field => {
      if(field.config.model !== null && (Questionnaire.validateModel(field.config.model) || typeof field.config.model === "boolean" || typeof field.config.model === "number")) {
	values[ field.config.name ] = field.config.model;
      } else {
	values[ field.config.name ] = null;
      }
    });

    return Api.runForm("tg", {
      action: "complete",
      data,
      values,
    });
  }

  static validateModel(value) {
    let returnValue = true;
    if(typeof value === "string") {
      returnValue = value.length > 0;
    }
    return returnValue;
  }

  static submitFormTest(form) {
    const values = {};
    const fields = cloneDeep(form.fields);

    fields.map((field, index) => {
      field.type      = capitalize(field.type);
      fields[ index ] = merge(field, field.config);
      delete fields[ index ].config;
    });

    form.fields.map(field => {
      if(field.config.model !== null && Questionnaire.validateModel(field.config.model)) {
	values[ field.config.name ] = field.config.model;
      } else {
	values[ field.config.name ] = null;
      }
      delete field.model;
    });

    return Api.testForm("tg", {
      fields,
      values,
    });
  }

}
